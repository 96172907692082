document.addEventListener("DOMContentLoaded", () => {
    const form = document.querySelector(".Garantieregistrierungsformular");
    if (form) {
        const serialNumberField = document.querySelector(".serialNumber");
        const warningMessage = document.createElement("span");
        warningMessage.style.color = "#ac1e35";
        warningMessage.style.fontWeight = "700";
        warningMessage.style.display = "none";
        // @ts-ignore
        warningMessage.textContent = window.TYPO3.lang.serialNumberValidationMessage;

        serialNumberField.parentNode.insertBefore(warningMessage, serialNumberField.nextSibling);

        const submitButton = form.querySelector('button[type="submit"]');
        const dataProtectionChecks = form.querySelectorAll('input[type="checkbox"]');

        function validateSerialNumber() {
            const value = serialNumberField.value;
            const regex = /^(L[A-Z0-9]{2,4}|P[A-Z0-9]{2})-[A-Z0-9]+$/i;

            if (!regex.test(value)) {
                warningMessage.style.display = "block";
                return false;
            } else {
                warningMessage.style.display = "none";
                return true;
            }
        }

        function areAllCheckboxesChecked() {
            return Array.from(dataProtectionChecks).every((checkbox) => checkbox.checked);
        }

        serialNumberField.addEventListener("blur", validateSerialNumber);

        form.addEventListener("submit", (event) => {
            const isSerialNumberValid = validateSerialNumber();
            const areCheckboxesValid = areAllCheckboxesChecked();

            if (!isSerialNumberValid || !areCheckboxesValid) {
                event.preventDefault();
            } else {
                setTimeout(function () {
                    submitButton.style.display = "none";
                }, 0);
            }
        });
    }
});
