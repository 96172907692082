//Cart Contact Form validation
const cartContactForm = document.querySelector("form.pricecheck-form");

if (cartContactForm) {
    cartContactForm.addEventListener("submit", (event) => {
        let isValid = true;

        for (let input of cartContactForm.elements) {
            if (input.required) {
                let error;

                if (input.type === "checkbox") {
                    const parentLabel = input.closest("label.pricecheck-form__checkbox");
                    error = parentLabel?.nextElementSibling;

                    if (!input.checked) {
                        isValid = false;

                        if (!error || !error.classList.contains("validation-error")) {
                            error = document.createElement("span");
                            error.className = "validation-error";
                            error.style.color = "#ac1e35";
                            error.style.fontWeight = "700";
                            error.style.display = "block";
                            error.style.marginTop = "5px";
                            error.textContent = window.TYPO3.lang.cartValidationCheckbox;
                            parentLabel.insertAdjacentElement("afterend", error);
                        }
                    } else if (error && error.classList.contains("validation-error")) {
                        error.remove();
                    }
                } else if (input.type === "email") {
                    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    error = input.nextElementSibling;

                    if (!emailRegex.test(input.value.trim()) || !input.value.trim()) {
                        isValid = false;

                        if (!error || !error.classList.contains("validation-error")) {
                            error = document.createElement("span");
                            error.className = "validation-error";
                            error.style.color = "#ac1e35";
                            error.style.fontWeight = "700";
                            error.textContent = window.TYPO3.lang.cartValidationEmail;
                            input.insertAdjacentElement("afterend", error);
                        }
                    } else if (error && error.classList.contains("validation-error")) {
                        error.remove();
                    }
                } else if (input.type === "tel") {
                    const phoneRegex = /^[0-9+\-()\s]*$/;
                    error = input.nextElementSibling;

                    if (!phoneRegex.test(input.value.trim()) || !input.value.trim()) {
                        isValid = false;

                        if (!error || !error.classList.contains("validation-error")) {
                            error = document.createElement("span");
                            error.className = "validation-error";
                            error.style.color = "#ac1e35";
                            error.style.fontWeight = "700";
                            error.textContent = window.TYPO3.lang.cartValidationPhone;
                            input.insertAdjacentElement("afterend", error);
                        }
                    } else if (error && error.classList.contains("validation-error")) {
                        error.remove();
                    }
                } else {
                    error = input.nextElementSibling;

                    if (!input.value.trim()) {
                        isValid = false;

                        if (!error || !error.classList.contains("validation-error")) {
                            error = document.createElement("span");
                            error.className = "validation-error";
                            error.style.color = "#ac1e35";
                            error.style.fontWeight = "700";
                            error.textContent = window.TYPO3.lang.cartValidationInputs;
                            input.insertAdjacentElement("afterend", error);
                        }
                    } else if (error && error.classList.contains("validation-error")) {
                        error.remove();
                    }
                }
            }
        }

        if (!isValid) {
            const firstInvalidField = cartContactForm.querySelector(".validation-error");
            firstInvalidField?.previousElementSibling?.focus();
            event.preventDefault();
        }
    });

    cartContactForm.addEventListener("change", (event) => {
        const input = event.target;

        if (input.type === "checkbox" && input.required) {
            const parentLabel = input.closest("label.pricecheck-form__checkbox");
            const error = parentLabel?.nextElementSibling;

            if (input.checked && error && error.classList.contains("validation-error")) {
                error.remove();
            }
        } else if (input.type === "email" || input.type === "tel") {
            const error = input.nextElementSibling;

            if (error && error.classList.contains("validation-error")) {
                error.remove();
            }
        }
    });
}

//Contact & Warranty form validation
const forms = document.querySelectorAll("form.contactformular, form.Garantieregistrierungsformular");

function validateForm(form) {
    form.noValidate = true;

    form.addEventListener("submit", (event) => {
        let isValid = true;

        for (let input of form.elements) {
            if (input.required) {
                let error;
                const parentGroup = input.closest(".form-group");

                if (input.type === "checkbox") {
                    const parentLabel = input.closest("label.form-check-label");
                    error = parentLabel?.nextElementSibling;

                    if (!input.checked) {
                        isValid = false;

                        if (!error || !error.classList.contains("validation-error")) {
                            error = document.createElement("span");
                            error.className = "validation-error";
                            error.style.color = "#ac1e35";
                            error.style.fontWeight = "700";
                            error.style.display = "block";
                            error.style.marginTop = "5px";
                            error.textContent = window.TYPO3.lang.cartValidationCheckbox;
                            parentLabel.insertAdjacentElement("afterend", error);
                        }
                        parentGroup && parentGroup.classList.add("error-present");
                    } else if (error && error.classList.contains("validation-error")) {
                        error.remove();
                        parentGroup && parentGroup.classList.remove("error-present");
                    }
                } else if (input.type === "email") {
                    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                    error = input.nextElementSibling;

                    if (!emailRegex.test(input.value.trim()) || !input.value.trim()) {
                        isValid = false;

                        if (!error || !error.classList.contains("validation-error")) {
                            error = document.createElement("span");
                            error.className = "validation-error";
                            error.style.color = "#ac1e35";
                            error.style.fontWeight = "700";
                            error.textContent = window.TYPO3.lang.cartValidationEmail;
                            input.insertAdjacentElement("afterend", error);
                        }
                        parentGroup && parentGroup.classList.add("error-present");
                    } else if (error && error.classList.contains("validation-error")) {
                        error.remove();
                        parentGroup && parentGroup.classList.remove("error-present");
                    }
                } else {
                    error = input.nextElementSibling;

                    if (!input.value.trim()) {
                        isValid = false;

                        if (!error || !error.classList.contains("validation-error")) {
                            error = document.createElement("span");
                            error.className = "validation-error";
                            error.style.color = "#ac1e35";
                            error.style.fontWeight = "700";
                            error.textContent = window.TYPO3.lang.cartValidationInputs;
                            input.insertAdjacentElement("afterend", error);
                        }
                        parentGroup && parentGroup.classList.add("error-present");
                    } else if (error && error.classList.contains("validation-error")) {
                        error.remove();
                        parentGroup && parentGroup.classList.remove("error-present");
                    }
                }
            }
        }

        if (!isValid) {
            const firstInvalidField = form.querySelector(".validation-error");
            firstInvalidField?.previousElementSibling?.focus();
            event.preventDefault();
        }
    });

    form.addEventListener("change", (event) => {
        const input = event.target;

        if (input.type === "checkbox" && input.required) {
            const parentLabel = input.closest("label.form-check-label");
            const error = parentLabel?.nextElementSibling;

            if (input.checked && error && error.classList.contains("validation-error")) {
                error.remove();
                const parentGroup = input.closest(".form-group");
                parentGroup && parentGroup.classList.remove("error-present");
            }
        } else if (input.required) {
            const error = input.nextElementSibling;

            if (input.value.trim() && error && error.classList.contains("validation-error")) {
                error.remove();
                const parentGroup = input.closest(".form-group");
                parentGroup && parentGroup.classList.remove("error-present");
            }
        }
    });
}

forms.forEach((form) => validateForm(form));
